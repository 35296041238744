import { Vector3 } from 'three'
import { useFrame } from 'react-three-fiber'

const v = new Vector3()
const worldDirection = new Vector3()

export const CalculatePositions = ({ hotspots, setPositions }) => {
  useFrame(({ camera, size }) => {
    camera.getWorldDirection(worldDirection)

    setPositions(
      hotspots.reduce(
        (result, hotspot) =>
          result.concat(
            hotspot.points.map(point => {
              v.copy(point.position)

              if (worldDirection.angleTo(v) < Math.PI / 2) {
                v.project(camera)

                return {
                  id: point.id,
                  left: ((v.x + 1) * size.width) / 2,
                  top: (-(v.y - 1) * size.height) / 2
                }
              } else {
                return {
                  id: point.id,
                  left: -20000,
                  top: -20000
                }
              }
            })
          ),
        []
      )
    )
  })

  return null
}

export default CalculatePositions
